import $ from 'jquery';

function externalLinks() {
if (!document.getElementsByTagName) return;
var anchors = document.getElementsByTagName("a");
for (var i=0; i<anchors.length; i++) {
var anchor = anchors[i];
if (anchor.getAttribute("href") &&
((anchor.getAttribute("rel") == "external nofollow") || (anchor.getAttribute("rel") == "nofollow external") || (anchor.getAttribute("rel") == "external")))
anchor.target = "_blank";
}
}
window.onload = externalLinks;